import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { ContentEntityIntro, IconTextValue, useLang } from '@shapeable/ui';
import { GptBannerIntro } from '../../data';
import { AiUpperCaseIconGlyph } from '@shapeable/icons';
const cls = classNames('explorer-gpt-banner-layout');

// -------- Types -------->

// -------- Props -------->

export type ExplorerGptBannerLayoutProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const ExplorerGptBannerLayoutDefaultProps: Omit<ExplorerGptBannerLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type BannerProps = {

};

// -------- Styles -------->

const BannerStyles = breakpoints({
  base: css`
  padding: ${theme.UNIT(5)} ${theme.UNIT(4)};
  border-top: 3px solid ${theme.COLOR('line-subtle')};
  border-radius: 0px 0px 8px 8px;
  background: ${theme.COLOR('app-chrome')};
`,
desktop: css`
  margin: 0 ${theme.UNIT(5)} 0;
`,
});

const IconHeaderStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(32)};
    padding-bottom: ${theme.UNIT(2)};
  `,
});

const IntroStyles = breakpoints({
  base: css`
    font-size: ${14/13}em;
    color: ${theme.COLOR('text')};
    line-height: 1.5em;
  `,
});

// -------- Components -------->

const My = {
  Banner: styled.div<BannerProps>`${BannerStyles}`,
    IconHeader: styled(IconTextValue)`${IconHeaderStyles}`,
      Intro: styled(ContentEntityIntro)`${IntroStyles}`,
};

export const ExplorerGptBannerLayout: Shapeable.FC<ExplorerGptBannerLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const t = useLang();
  
  return (
    <My.Banner className={cls.name(className)}>
      <My.IconHeader color='dark' iconSize={55} reverse={true} iconComponent={AiUpperCaseIconGlyph}>{t('Copper')}</My.IconHeader>
      <My.Intro entity={GptBannerIntro} />
    </My.Banner>
  )
};

ExplorerGptBannerLayout.defaultProps = ExplorerGptBannerLayoutDefaultProps;
ExplorerGptBannerLayout.cls = cls;