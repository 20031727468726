import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue, CitationsProvider as Provider } from '@shapeable/ui';
import { sortBy } from 'lodash';

const CITATIONS_QUERY = graphql`
query PlatformAllCitationsQuery {
  
    platform { allCitations {
      
        
      
          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
      
        
        
              openGraph { 
                id title 
                
                image { 
                  id url url2x 
                  thumbnails { 
                    id bubble { id url url2x }
        card { id url url2x }
                  }
                  
                } 
              }
            
      
      authors { id name slug } authorShowsEtAl edition publication date accessDate startPage volume footnoteNumber year
      type { name id }
      topics { id name slug path __typename _schema { label pluralLabel } }
      trends { id name slug path __typename _schema { label pluralLabel } }
    } }
  
}

`;

export const CitationsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ sortBy(platformProviderValue( useStaticQuery(CITATIONS_QUERY), 'allCitations' ), 'name') }>{children}</Provider>;
};
