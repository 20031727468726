import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, entityTypeNameFor } from '@shapeable/utils';
import { ExplorerProvider } from '../providers/explorer-provider';
import { EntityConversationsLayout, ExplorerEntityDetailsLayout, useActivePerson, useTrends, EntityConversationsAsideLayout } from '@shapeable/ui';
const cls = classNames('radar-home-layout');

// -------- Types -------->

export type ExplorerConversationsLayoutProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const ExplorerConversationsLayoutDefaultProps: Omit<ExplorerConversationsLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->


const ContainerStyles = breakpoints({
  base: css`
    background: ${theme.COLOR('bright')};
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    .shp--explorer-entity-details-layout__banner-and-tabs {
      display: none;
    }
  `,
  desktop: css`
    
  `,
});

const ConversationsStyles = breakpoints({
  base: css`
  `,
});

const ConversationsAsideStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Layout: styled(ExplorerEntityDetailsLayout)`${LayoutStyles}`,

  Conversations: styled(EntityConversationsLayout)`${ConversationsStyles}`,
  ConversationsAside: styled(EntityConversationsAsideLayout)`${ConversationsAsideStyles}`,
};

export const ExplorerConversationsLayout: Shapeable.FC<ExplorerConversationsLayoutProps> = (props) => {
  const { className, children, entity } = props;
  
  const trends = useTrends();
  


  return (
    <ExplorerProvider>

    <My.Container className={cls.name(className)}>
      <My.Layout
        asideMaxWidth={400}
        asideHeaderProps={{ navigationProps: { showIcon: false }}}
        entity={entity}
        isAsideSticky
        stickyTopOffset={106}
        asideIsRevealed={false}
        content={
          <My.Conversations items={trends} />
        }
        metaHead={
          <My.ConversationsAside 
            items={trends} 
            intro={{
              text: 'Select a Conversation to participate in, by Value Theme below.',
            }}
          />
        }
      />
      {children}
    </My.Container>
    </ExplorerProvider>
  )
};

ExplorerConversationsLayout.defaultProps = ExplorerConversationsLayoutDefaultProps;
ExplorerConversationsLayout.cls = cls;