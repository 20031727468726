import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, ContentNode as TContentNode } from '@shapeable/types';
import { breakpoints, theme, ColorTokens } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { SubTopic } from '@shapeable/copper-connect-types';
import { ContentEntityIntro, ContentNode, ContentTabs, DottedLine, EntityFilters, EntityGrid, EntityGridDetailsNavigation, NextPageLink, useView } from '@shapeable/ui';
import { GptLayout } from '@shapeable/gpt';
import { SubTopicView } from 'types';
import { compact } from 'lodash';

const cls = classNames('sub-topic-view-layout');

// -------- Types -------->

// -------- Props -------->

export type SubTopicViewLayoutProps = Classable & HasChildren & { 
  entity?: SubTopic;
};

export const SubTopicViewLayoutDefaultProps: Omit<SubTopicViewLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const SectionStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    margin: ${theme.UNIT(7)} 0;
    gap: ${theme.UNIT(5)};
  `,
});

const ContentTitleStyles = breakpoints({
  base: css`
    
  `,
});

const DescriptionStyles = breakpoints({
  base: css`
    
  `,
});

const TabsStyles = breakpoints({
  base: css`
    width: 100%;
    padding-top: ${theme.UNIT(1)};
    margin-top: ${theme.UNIT(2)}; 
  `,
});

const IntroStyles = breakpoints({
  base: css`
    font-size: 1.35em; 
  `,
});

const NextLinkStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(5)};
    
  `,
});

const FiltersStyles = breakpoints({
  base: css`
    
  `,
});


const GptLayoutStyles = breakpoints({
  base: css`
 
  `,
});

const GridStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
  Section: styled.section`${SectionStyles}`,
      ContentTitle: styled.h2`${ContentTitleStyles}`,
      
      Intro: styled(ContentEntityIntro)`${IntroStyles}`,
      Description: styled(ContentNode)`${DescriptionStyles}`,
      
      Grid: styled(EntityGrid)`${GridStyles}`,

      Filters: styled(EntityFilters)`${FiltersStyles}`,
      Tabs: styled(ContentTabs)`${TabsStyles}`,

      NextLink: styled(NextPageLink)`${NextLinkStyles}`,

    GptLayout: styled(GptLayout)`${GptLayoutStyles}`,
};

export const SubTopicViewLayout: Shapeable.FC<SubTopicViewLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const view = `sub-topic-${entity?.id}`;

  const { feedEntries = [], pages = [], posts = [], citations = [], 
    description } = entity;

  const libraryItems = compact([...posts, ...feedEntries, ...pages, ...citations ]);

  const { filters, showView } = useView<SubTopicView>(view, 'deep-dive');
  
  return (
    <My.Container className={cls.name(className)}>
    {
      showView('copper-ai') && 
      <My.Section>
        <My.GptLayout entity={entity} />
      </My.Section>
    }
    {
      showView('deep-dive') &&
      <My.Section>
        <My.Intro entity={entity} />
        <My.Description entity={description as TContentNode} /> 
        <DottedLine height={2} />
        <My.NextLink />
      </My.Section>
    }
    {
      showView('library') && 
      <My.Section>
        <My.Grid spacing={4} items={libraryItems} />
      </My.Section>
    }
    </My.Container>
  )
};

SubTopicViewLayout.cls = cls;