import { useEconomies, useHorizonTypes, useOrganisationTypes } from "@shapeable/ui";
import { useCatalysts } from "./use-catalyst";
import { useTechnologies } from "./use-technologies";
import { useTrendsExplorer } from "./use-trends-explorer";
import { sortBy, compact } from 'lodash';
import { HorizonType, Innovation } from "@shapeable/copper-connect-types";
import { useInnovations } from "./use-innovations";
import { useInnovationPriorities } from "./use-innovation-priorities";

export type InnovationsFiltersVariant = 'explorer' | 'topic';
export const useInnovationsFilters = (variant?: InnovationsFiltersVariant) => {

  const variantToUse = variant || 'explorer';

  const allTrends = useTrendsExplorer();
  const allTechnologies = useTechnologies();
  const allInnovationPriorities = useInnovationPriorities();

  return { 
    selects: compact([
      variantToUse === 'explorer' && 
      {
        name: 'themes',
        fieldName: 'trends',
        items: sortBy(allTrends, 'outlineNumber'),
        pluralLabel: 'Value Chain Themes',
        getResultFieldSlugs: (result: Innovation) => result.trends?.map((trend) => trend.slug),
      },
      {
        name: 'innovationPriority',
        fieldName: 'innovationPriority',
        items: sortBy(allInnovationPriorities, 'name'),
        pluralLabel: 'Innovation Priorities',
        getResultFieldSlugs: (result: Innovation) => result.innovationPriority?.map((innovationPriority) => innovationPriority.slug),
      },
      {
        name: 'technologies',
        items: sortBy(allTechnologies, 'name'),
        pluralLabel: 'Technologies',
        getResultFieldSlugs: (result: Innovation) => result.technologies?.map((tech) => tech.slug),

      },
    ])
  };
};