import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { ExplorerProvider } from '../providers/explorer-provider';
import { BackButtonEntityProvider, EntityTypeBannersProvider, PAGE_HOME, PageHalfBanner } from '@shapeable/ui';
import { PageExplorerLayout } from '../entities/page-explorer-layout';

const cls = classNames('page-layout-impact-strategy');

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    
  `,
});

const LayoutStyles = breakpoints({
  base: css`
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div`${ContainerStyles}`,
  Layout: styled(PageExplorerLayout)`${LayoutStyles}`,
};

export const PageLayoutImpactStrategy: PageLayoutComponent = (props) => {
  const { className, entity, slices } = props;

  const backEntity = entity.parent && entity.parent.id !== entity.id ? entity.parent : PAGE_HOME;

  return (
    <My.Container className={cls.name(className)}>
      <ExplorerProvider>
      <BackButtonEntityProvider value={backEntity}>
        <My.Layout 
          entity={entity} 
          showRadar 
          isAsideSticky 
          className={className} 
        />
      </BackButtonEntityProvider>
      </ExplorerProvider>
    </My.Container>
  )
};
