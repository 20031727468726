import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { SliceLayoutBoundary, SiteHeaderLayout, ExplorerEntityDetailsLayoutDefaultProps, ExplorerEntityDetailsLayout, ComponentPropsProvider, ExplorerEntityDetailsLayoutProps, BackButtonEntityProvider, SiteHeaderProvider, EntityMainHeader, ExplorerTabs, } from '@shapeable/ui';
import { ExplorerProvider, PAGE_NAVIGATOR } from '../providers/explorer-provider';
import { Innovation, Topic } from '@shapeable/copper-connect-types';
import { ValueChainNavigator } from '../elements/value-chain-navigator';
import { useValueChainMap } from '../../hooks/use-value-chain-map';
import { ValueChainNavigatorMain } from '../elements/value-chain-navigator-main';
import { InnovationTabsLayout } from './innovation-tabs-layout';
import { InnovationViewLayout } from './innovation-view-layout';
import { InnovationAsideLayout } from './innovation-aside-layout';
const cls = classNames('innovation-explorer-layout');

// -------- Types -------->

// -------- Props -------->

export type InnovationExplorerLayoutProps = ExplorerEntityDetailsLayoutProps & { 
  entity?: Innovation;
};

export const InnovationExplorerLayoutDefaultProps: Omit<InnovationExplorerLayoutProps, 'entity'> = {
  ...ExplorerEntityDetailsLayoutDefaultProps,
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(4)};
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    
  `,
});

const BannerStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(4)} ${theme.UNIT(4)} 0;
  `,
  desktopDevice: css`
    ${theme.H_DOT_BG('line-subtle')};
    padding: ${theme.UNIT(4)} 0 0;
  `,
});

const RadarChartStyles = breakpoints({
  base: css`
  `,
});

const TabsStyles = breakpoints({
  base: css`
    
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Banner: styled(EntityMainHeader)`${BannerStyles}`,
    Header: styled(SiteHeaderLayout)`${HeaderStyles}`,
    Layout: styled(ExplorerEntityDetailsLayout)`${LayoutStyles}`,
      RadarChart: styled(ValueChainNavigator)`${RadarChartStyles}`,

    Tabs: styled(ExplorerTabs)`${TabsStyles}`,

};

export const InnovationExplorerLayout: Shapeable.FC<InnovationExplorerLayoutProps> = (props) => {
  const { className, children, entity } = props;
  const { isShown } = useValueChainMap();

  return (
    <SiteHeaderProvider value={{ 
      variant: 'default',
    }}>
    <ExplorerProvider>
    <BackButtonEntityProvider value={PAGE_NAVIGATOR}>
    <My.Container>
    <My.Header/>
      <SliceLayoutBoundary boundary='none' >
      <My.Tabs useStickyStyle />
      <My.Layout 
        entity={entity}
        className={cls.name(className)}
        asideIsRevealed={isShown}
        isAsideSticky={false}
        banner={
          <My.Banner entity={entity} />
        }

        //main
        tabs={
          <InnovationTabsLayout entity={entity} />
        }
        content={
          <InnovationViewLayout entity={entity} />
        }

        metaHead={
          <InnovationAsideLayout entity={entity} />
        }
        
        />
        </SliceLayoutBoundary>
    </My.Container>
    </BackButtonEntityProvider>
    </ExplorerProvider>
    </SiteHeaderProvider>
  )
};

InnovationExplorerLayout.defaultProps = InnovationExplorerLayoutDefaultProps;
InnovationExplorerLayout.cls = cls;