import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, Entity, HasChildren, Shapeable, Trend } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { OutlineEntityDotLink, useEntity } from '@shapeable/ui';
import { SubTopic, Topic } from '@shapeable/copper-connect-types';
import { classNames } from '@shapeable/utils';
const cls = classNames('sub-topics-embed');

import { sortBy } from 'lodash';

// -------- Types -------->

export type EntityWithTopics = Entity & {
  subTopics?: SubTopic[];
}

export type SubTopicsEmbedProps = Classable & HasChildren & { 
};

export const SubTopicsEmbedDefaultProps: SubTopicsEmbedProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(4)};
  `,
});

const ItemStyles = breakpoints({
  base: css`
  
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Item: styled(OutlineEntityDotLink)<ContainerProps>`${ItemStyles}`,

};

export const SubTopicsEmbed: Shapeable.FC<SubTopicsEmbedProps> = (props) => {
  const { className, children } = props;

  
  
  // embeds the subTopics for the current trend

  const entity = useEntity() as Topic;

  return (
    <My.Container className={cls.name(className)}>
    {
      sortBy(entity.subTopics, 'outlineNumber').map(subTopic => <My.Item entity={subTopic} key={entity.id} />)
    }
    </My.Container>
  )
};

SubTopicsEmbed.defaultProps = SubTopicsEmbedDefaultProps;
SubTopicsEmbed.cls = cls;