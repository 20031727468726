import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue, PeopleProvider as Provider } from '@shapeable/ui';

const QUERY = graphql`
query PlatformAllPeopleQuery {
  
    platform { allPeople {
      
        
      
          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
      
        
        
              openGraph { 
                id title 
                
                image { 
                  id url url2x 
                  thumbnails { 
                    id bubble { id url url2x }
        card { id url url2x }
                  }
                  
                } 
              }
            
      
      linkedin threads twitter facebook
      position
      organisation { 
        id name 
        location {
          __typename id slug name
          latitude longitude
        }
      }
    }
  
  allPeople {
    economies { slug name }
    economy { 
      id name slug
      openGraph { image { id url url2x thumbnails { id bubble { id url url2x } card { id url url2x } } } }
    }
    trends {
      
        
      
          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
      
        
        
              openGraph { 
                id title 
                
                image { 
                  id url url2x 
                  thumbnails { 
                    id bubbleMedium { id url url2x }
                  }
                  
                } 
              }
            
      
    }
  } }
}

`

export const PeopleProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allPeople' ) }>{children}</Provider>;
}