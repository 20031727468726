import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, ContentNode, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { EntityFilters, EntityTypeCardsProvider, ExplorerEntityGridLayout, OrganisationLogoCard, OrganisationMarketMapGrid, SearchBar, useOrganisations, useSearch, useView } from '@shapeable/ui';
import { compact, sortBy } from 'lodash';
import { linkedMatchesFilter } from '../../utils/linked-matches-filter';
import { useMarketMapFilters } from '../../hooks/use-market-map-filters';
import { Organisation } from '@shapeable/copper-connect-types';
import { classNames } from '@shapeable/utils';

const cls = classNames('explorer-market-map-layout');

// -------- Types -------->

export type ExplorerMarketMapLayoutProps = Classable & HasChildren & {
}

export const ExplorerMarketMapLayoutDefaultProps: ExplorerMarketMapLayoutProps = {

};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`

  `,
});

const FiltersStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled(ExplorerEntityGridLayout)<ContainerProps>`${ContainerStyles}`,
    Filters: styled(EntityFilters)`${FiltersStyles}`,
};

export const ExplorerMarketMapLayout: Shapeable.FC<ExplorerMarketMapLayoutProps> = (props) => {
  const { className } = props;

  const allOrganisations = useOrganisations() as Organisation[];
  const marketMapFilters = useMarketMapFilters('explorer-community');
  const { selects } = marketMapFilters;

  const view = 'explorer';
  const { filters } = useView(view);
  
  const { countries = [], types = [], technologies = [], themes = [] } = filters;

  const organisations = sortBy(allOrganisations.filter((organisation: Organisation) => (
      linkedMatchesFilter(compact([organisation?.type]), types) &&
      linkedMatchesFilter([organisation.country], countries) &&
      linkedMatchesFilter(organisation.technologies, technologies) && 
      linkedMatchesFilter(organisation?.trends, themes)
    )), 'name');

    const description: ContentNode = {
        text: 'A listing of significant organisations and innovators across the world, some being Partners, to be discovered by area of activity',
      }

  return (
    <My.Container 
      className={cls.name(className)} 
      view={view}
      showSearchBar
      items={organisations}
      spacing={4}
      description={description}
      filters={
        <My.Filters 
          view={view}
          results={allOrganisations}
          selects={selects}
        />
      }
    />
  )
};

ExplorerMarketMapLayout.cls = cls;
ExplorerMarketMapLayout.defaultProps = ExplorerMarketMapLayoutDefaultProps;