import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { PostsProvider as Provider, platformProviderValue } from '@shapeable/ui';

const POSTS_QUERY = graphql`
query PlatformAllPostsQuery {
  
    platform { allPosts {
      
        
      
          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
      
        
        
              openGraph { 
                id title 
                
                image { 
                  id url url2x 
                  thumbnails { 
                    id bubble { id url url2x }
        card { id url url2x }
                  }
                  
                } 
              }
            
      
      content { text(truncate: { words: 20 }) }
      title
      type { id name slug __typename }
      published
      people {
        
          
        
            id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
        
          
          
                openGraph { 
                  id title 
                  
                  image { 
                    id url url2x 
                    thumbnails { 
                      id bubbleMedium { id url url2x }
                    }
                    
                  } 
                }
              
        
        organisation { id name }
        linkedin
        position
      }
      authors {
        
          
        
            id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
        
          
          
                openGraph { 
                  id title 
                  
                  image { 
                    id url url2x 
                    thumbnails { 
                      id bubbleMedium { id url url2x }
                    }
                    
                  } 
                }
              
        
        organisation { id name }
        linkedin
      }
    } }
  
}

`;

export const PostsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(POSTS_QUERY), 'allPosts' ) }>{children}</Provider>;
};