
import { compact, sortBy } from "lodash";
import { TrendView } from "../types";
import { CommodityGroup, Trend, Organisation, Person } from "@shapeable/copper-connect-types";
import { useCommodityGroups } from "./use-commodity-groups";
import { Citation, Entity, Page, Post } from "@shapeable/types";
import { transformPage } from "../utils/transforms";
import { useView } from "@shapeable/ui";


type TrendViewDefiniton = {
  hasLibrary: boolean;
  hasDeposits: boolean;
  hasStakeholders: boolean;
  isDiscoveryTrend: boolean;
  stakeholders: Organisation[] | Person[];
  copper: CommodityGroup;
  libraryItems: Entity[];
  defaultView: TrendView;
  filteredLibraryItems: Citation[] | Post[] | Page[];
};

export const useTrendViewDefinition: (trend: Trend) => TrendViewDefiniton = 
  (entity: Trend) => {
    const { feedEntries = [], pages = [], posts = [], citations = [], organisations = [],
      people = []
     } = entity;

    const { resultsFor } = useView(`trend-${entity.path}`);

    const transformedPages = pages.map(transformPage);

    const libraryItems = compact([...posts, ...feedEntries, ...transformedPages, ...citations ]);

    const commodityGroups = useCommodityGroups();
    const copper = commodityGroups.find(item => item.slug === 'copper');

    const stakeholders = [...sortBy(organisations, 'name'), ...sortBy(people, 'name')];

    const hasStakeholders = !!stakeholders.length;
    const hasDeposits = !!copper;
    const hasLibrary = !!libraryItems.length;
    const isDiscoveryTrend = entity.slug === 'discovery';

    const defaultView: TrendView = isDiscoveryTrend ? 'deposits' : 'overview';

    const filteredLibraryItems = sortBy([
      ...resultsFor({ type: 'Post', data: posts, by: 'name' }),
      ...resultsFor({ type: 'Citation', data: citations, by: 'name' }),
      ...(resultsFor({ type: 'ImpactStrategy', data: transformedPages, by: 'name' })), 
    ], 'name');

    return {
      hasLibrary,
      hasDeposits,
      stakeholders,
      hasStakeholders,
      isDiscoveryTrend,
      copper,
      libraryItems,
      defaultView,
      filteredLibraryItems,
    };
    
  };