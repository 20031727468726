import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Page, Post, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { SliceLayoutBoundary, SiteHeaderLayout, EntityBannerProps, ExplorerEntityDetailsLayoutDefaultProps, ExplorerEntityDetailsLayout, ExplorerEntityDetailsLayoutProps, ComponentPropsProvider, PAGE_HOME, BackButtonEntityProvider, SvgCanvasViewBoxDefinition, DownloadButton, useLang, useAuth, SiteHeaderProvider, ExplorerTabs, EntityBanner } from '@shapeable/ui';
import { ExplorerProvider } from '../providers/explorer-provider';
import { ValueChainNavigator } from '../elements/value-chain-navigator';
import { ValueChainNavigatorMain } from '../elements/value-chain-navigator-main';
const cls = classNames('page-explorer-layout');

// -------- Types -------->

type RadarProps = {
  isInteractive: boolean;
}

// -------- Props -------->

export type PostLayoutProps = ExplorerEntityDetailsLayoutProps & {  
  entity?: Post;
  bannerProps?: Omit<EntityBannerProps, 'entity'>;
  showRadar?: boolean;
  isAsideSticky?: boolean;
};

export const PostLayoutDefaultProps: Omit<PostLayoutProps, 'entity'> = {
  ...ExplorerEntityDetailsLayoutDefaultProps,
  isAsideSticky: false,
  showRadar: true,
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(4)};

    .shp--entity-banner {
      h2.shp--entity-value__name {
        font-size: ${theme.FONT_SIZE(30)} !important;
      }
    }
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    
  `,
});


const NavigatorStyles = breakpoints({
  base: css`

  `,
});

const TabsStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Header: styled(SiteHeaderLayout)`${HeaderStyles}`,
    Tabs: styled(ExplorerTabs)`${TabsStyles}`,
      Layout: styled(ExplorerEntityDetailsLayout)`${LayoutStyles}`,
      Navigator: styled(ValueChainNavigator)`${NavigatorStyles}`,

      
};

export const PostLayout: Shapeable.FC<PostLayoutProps> = (props) => {
  const { className, children, entity, bannerProps, showRadar, isAsideSticky, ...explorerEntityDetailsProps } = props;

  const t = useLang();

  const auth = useAuth();

  return (
    <ExplorerProvider>
    <SiteHeaderProvider value={{ 
      variant: 'default',
    }}>
    <BackButtonEntityProvider value={entity}>
    <My.Container>
      <ComponentPropsProvider value={{
        EntityBanner: {
          variant: 'image',
          maxHeight: 400,
          showSubheader: true,
          useBackgroundColor: false,
          entityValueProps: { showImage: false, showOutlineNumber: false }
        },
      }}>
      <My.Header />
      <My.Tabs useStickyStyle />
      <SliceLayoutBoundary boundary='none' >
        <My.Layout 
          entity={entity}
          className={cls.name(className)}
          {...explorerEntityDetailsProps}
          asideMaxWidth={800}
          isAsideSticky={true}
          stickyTopOffset={64}

          dataVizMain={
            <ValueChainNavigatorMain />
          }
  
          //aside
          dataViz={
            <My.Navigator />
          }
        />
        </SliceLayoutBoundary>
      </ComponentPropsProvider>
    </My.Container>
    </BackButtonEntityProvider>
    </SiteHeaderProvider>
    </ExplorerProvider>
  )
};

PostLayout.defaultProps = PostLayoutDefaultProps;
PostLayout.cls = cls;