import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, ContentNode as TContentNode } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, linkedMatchesFilter } from '@shapeable/utils';
import { ContentEntityIntro, ContentNode, ContentTabs, DottedLine, EntityFilters, EntityGrid, NextPageLink, useView } from '@shapeable/ui';
import { useInnovationsFilters } from '../../hooks/use-innovations-filters';
import { TopicView } from '../../types';
import { InnovationHorizonTableList } from './innovation-horizon-table-list';
import { GptLayout } from '@shapeable/gpt';
import { Topic } from '@shapeable/copper-connect-types';
import { compact, sortBy } from 'lodash';
const cls = classNames('topic-view-layout');

// -------- Types -------->

// -------- Props -------->

export type TopicViewLayoutProps = Classable & HasChildren & { 
  entity?: Topic;
};

export const TopicViewLayoutDefaultProps: Omit<TopicViewLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const SectionStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    margin: ${theme.UNIT(7)} 0;
    gap: ${theme.UNIT(5)};
  `,
});

const ContentTitleStyles = breakpoints({
  base: css`
    
  `,
});

const DescriptionStyles = breakpoints({
  base: css`
    
  `,
});

const InnovationTableStyles = breakpoints({
  base: css`
    
  `,
});

const TabsStyles = breakpoints({
  base: css`
    width: 100%;
    padding-top: ${theme.UNIT(1)};
    margin-top: ${theme.UNIT(2)}; 
  `,
});

const IntroStyles = breakpoints({
  base: css`
    font-size: 1.35em; 
  `,
});

const NextLinkStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(5)};
    
  `,
});

const FiltersStyles = breakpoints({
  base: css`
    
  `,
});


const BodyStyles = breakpoints({
  base: css`
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 330px);
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(5)};
  `,
  tablet: css`
    height: calc(100vh - 312px);
  `,
  desktop: css`
    height: calc(100vh - 366px);
  `,
});

const GptLayoutStyles = breakpoints({
  base: css`
 
  `,
});

const GridStyles = breakpoints({
  base: css`
    
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Section: styled.section`${SectionStyles}`,
      ContentTitle: styled.h2`${ContentTitleStyles}`,
      
      Intro: styled(ContentEntityIntro)`${IntroStyles}`,
      Description: styled(ContentNode)`${DescriptionStyles}`,
      Body: styled.div`${BodyStyles}`,
      InnovationTable: styled(InnovationHorizonTableList)`${InnovationTableStyles}`,
      
      Grid: styled(EntityGrid)`${GridStyles}`,

      Filters: styled(EntityFilters)`${FiltersStyles}`,
      Tabs: styled(ContentTabs)`${TabsStyles}`,

      NextLink: styled(NextPageLink)`${NextLinkStyles}`,

    GptLayout: styled(GptLayout)`${GptLayoutStyles}`,

};

export const TopicViewLayout: Shapeable.FC<TopicViewLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const { feedEntries = [], pages = [], posts = [], citations = [], 
    description } = entity;

  const libraryItems = compact([...posts, ...feedEntries, ...pages, ...citations ]);

  const view = entity.path;
  const { filters, showView } = useView<TopicView>(view, 'deep-dive');
  const { technologies = [], catalysts = [], horizonTypes = [] } = filters;
  const { selects } = useInnovationsFilters('topic');

  const filteredInnovations = sortBy(entity.innovations.filter(innovation => (
    linkedMatchesFilter(innovation.technologies, technologies) &&
    linkedMatchesFilter(innovation.catalysts, catalysts) &&
    linkedMatchesFilter(compact(innovation.horizons?.map(h => h.type)), horizonTypes)
  )), 'name');
  
  return (
    <My.Container className={cls.name(className)}>
      {
        showView('copper-ai') && 
        <My.Section>
          <My.GptLayout entity={entity} />
        </My.Section>
      }
      {
        showView('deep-dive') &&
        <My.Section>
          <My.Intro entity={entity} />
          <My.Description entity={description as TContentNode} /> 
          <DottedLine height={2} />
          <My.NextLink />
        </My.Section>
      }
      {
        showView('innovations') &&
        <My.Section>
          <My.Filters
            results={entity.innovations} 
            view={view}
            selects={selects} 
          />
          <My.Body>
            <My.InnovationTable items={filteredInnovations} />
            <DottedLine height={2} />
            <My.NextLink />
          </My.Body>
          
        </My.Section>
      }
      {
        showView('library') && 
        <My.Section>
          <My.Grid spacing={4} items={libraryItems} />
        </My.Section>
      }
    </My.Container>
  )
};

TopicViewLayout.defaultProps = TopicViewLayoutDefaultProps;
TopicViewLayout.cls = cls;