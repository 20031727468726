import React from 'react';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { HidePanelButton, useContributeForm } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
import { FeedbackIconGlyph } from '@shapeable/icons';
import { ContributeForm } from './contact-form';

const cls = classNames('contribute-form-panel');

// -------- Types -------->

export type ContributeFormPanelProps = Classable & HasChildren & {
  activeTopic?: string;
}

export const ContributeFormPanelDefaultProps: ContributeFormPanelProps = {
};

// -------- Child Component Props -------->

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    background: #000;
    box-sizing: border-box;
    position: relative;
    height: calc(100vh - 40px);
  `,
  desktop: css`
    height: 100vh;
  `
});



const HideButtonStyles = breakpoints({
  base: css`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: none;

  `,
  desktop: css`
    display: flex;
  `,
});

const HideButtonHorizontalStyles = breakpoints({
  base: css`
    flex-shrink: 0;
    flex-grow: 1;
    width: 100%;
  `,
  desktop: css`
    display: none;
  `,
});

const FormStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(4)};
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    top: 42px;
    height: calc(100vh - 42px);
    overflow-y: auto;
  `,
  desktop: css`
    top: 0px;
    left: 54px;
    height: 100%;
    width: calc(100% - 54px);
    
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<any>`${ContainerStyles}`,
    HideButton: styled(HidePanelButton)`${HideButtonStyles}`,
    Form: styled(ContributeForm)`${FormStyles}`,
    HideButtonHorizontal: styled(HidePanelButton)`${HideButtonHorizontalStyles}`,
};

export const ContributeFormPanel: Shapeable.FC<ContributeFormPanelProps> = (props) => {
  const { className } = props;
  const contributeForm = useContributeForm();

  const onContainerClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <My.Container onClick={onContainerClick} className={cls.name(className)}>
      <My.HideButtonHorizontal icon={<FeedbackIconGlyph />} label="HIDE" onClick={contributeForm.hideOnClick} />
      <My.HideButton icon={<FeedbackIconGlyph />} label="HIDE" isVertical onClick={contributeForm.hideOnClick} />
      <My.Form />
    </My.Container>
  )
};

ContributeFormPanel.defaultProps = ContributeFormPanelDefaultProps;
ContributeFormPanel.cls = cls;