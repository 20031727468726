import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue } from '@shapeable/ui';
import { EconomiesProvider as Provider } from '@shapeable/ui';
import { sortBy } from 'lodash';
import { Economy } from '@shapeable/copper-connect-types';

const ECONOMIES_QUERY = graphql`
query PlatformAllEconomiesQuery {
  
    platform { allEconomies {
      
        
      
          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
      
        
        
              openGraph { 
                id title 
                
                image { 
                  id url url2x 
                  thumbnails { 
                    id bubble { id url url2x }
        card { id url url2x }
                  }
                  
                } 
              }
            
      
      globalRegions { 
         
           
         
            id name slug typeLabel badge path updated __typename _schema { label pluralLabel } 
         
           
           
                openGraph {  
                  id title  
                   
                  image {  
                    id url url2x  
                    thumbnails {  
                      id bubbleMedium { id url url2x } 
                    } 
                     
                  }  
                } 
               
        
        abbreviation
      }
    }
  
  allEconomies {
    depositsCount
  } }
}
`;

export const EconomiesProvider: React.FC<any> = ({ children }) => {
  const economies: Economy[] = platformProviderValue( useStaticQuery(ECONOMIES_QUERY), 'allEconomies' );
  // filter out economies with no deposits  

  const value = (economies || []).filter(economy => !!economy.depositsCount);
  return <Provider value={ value }>{children}</Provider>;
};