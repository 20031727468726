import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue, HorizonTypesProvider as Provider } from '@shapeable/ui';

const QUERY = graphql`
query PlatformAllHorizonTypesQuery {
  
    platform { allHorizonTypes {
      
        
      
          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
      
        
        
              openGraph { 
                id title 
                
                image { 
                  id url url2x 
                  thumbnails { 
                    id bubble { id url url2x }
        card { id url url2x }
                  }
                  
                } 
              }
            
      
      years subtitle
    }
  
  allHorizonTypes {
    years subtitle value
  } }
}

`
export const HorizonTypesProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allHorizonTypes' ) }>{children}</Provider>;
}
