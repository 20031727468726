import { useEconomies, useOrganisations, useOrganisationTypes, useTrends } from "@shapeable/ui";
import { useTechnologies } from "./use-technologies";
import { compact, sortBy } from 'lodash';
import { Economy, Organisation, Person, Technology, Trend } from "@shapeable/copper-connect-types";
import { useInnovationPriorities } from "./use-innovation-priorities";


export type MarketMapFiltersVariant = 'explorer-community' | 'innovation-community';
export const useMarketMapFilters = (page?: MarketMapFiltersVariant) => {
  const allEconomies = useEconomies();
  const allOrganisationTypes = useOrganisationTypes();
  const allOrganisations = useOrganisations();
  const allTechnologies = useTechnologies();
  const allInnovationPriorities = useInnovationPriorities();
  const allTrends = useTrends();


  const filterCountries = ( entities: Economy[], orgs: (Organisation | Person)[] ) => {
      return (entities || []).filter((entity) =>
        
        (orgs || []).some((org) => org.country?.slug === entity.slug)
      );
    };
  
    const filterTrends = (entities: Trend[], orgs: Organisation[] | Person[]) => {
      return (entities || []).filter((trend) =>
        (orgs || []).some((org) => org.trends?.some((orgTrend) => orgTrend.slug === trend.slug))
      );
    };
  
    const filterTechnologies = (entities: Technology[], orgs: Organisation[] | Person[]) => {
      return (entities || []).filter((tech) =>
        (orgs || []).some((org) => org.technologies?.some((orgTech) => orgTech.slug === tech.slug))
      );
    }

    const finalCountries = filterCountries(allEconomies, allOrganisations);
    const finalTechnologies = filterTechnologies(allTechnologies, allOrganisations);
    const finalTrends = filterTrends(allTrends, allOrganisations);



  return {
    selects: compact([
      finalTechnologies.length && {
        name: 'technologies',
        items: sortBy(finalTechnologies, 'name'),
        pluralLabel: 'Technologies',
        getResultFieldSlugs: (result: Organisation) => result.technologies?.map(t => t.slug),
      },
      !!allEconomies.length && {
        name: 'countries',
        items: sortBy(finalCountries, 'name'),
        pluralLabel: 'Countries',
        getResultFieldSlugs: (result: Organisation | Person) => [result.country?.slug],
      },
      {
        name: 'types',
        items: sortBy(allOrganisationTypes, 'name'),
        pluralLabel: 'Stakeholder Types',
        getResultFieldSlugs: (result: Organisation) => [result.type?.slug],
      },
      // {
      //   name: 'innovation Priority',
      //   fieldName: 'innovation Priorities',
      //   items: sortBy(allInnovationPriorities, 'name'),
      //   pluralLabel: 'Innovation Priorities',
      //   // getResultFieldSlugs: (result: Organisation | Person) => result.innovationPriorities?.map((innovationPriority) => innovationPriority.slug),
      // },
      {
        name: 'themes',
        fieldName: 'trends',
        items: sortBy(finalTrends, 'outlineNumber'),
        pluralLabel: 'Value Chain Themes',
        getResultFieldSlugs: (result: Organisation | Person) => result.trends?.map((trend) => trend.slug),
      },
    ]),

  };
}