import * as React from 'react';
import { graphql } from 'gatsby';
import { createGatsbyPageComponent } from '@shapeable/ui';
import { SubTopicExplorerLayout } from '../../components/entities/sub-topic-explorer-layout';

export default createGatsbyPageComponent('SubTopic', { layout: SubTopicExplorerLayout });

export const QUERY = graphql`
query SubTopicQuery($id: ID!) {
  platform { subTopic(id: $id) {
  __typename _schema { label pluralLabel }
    openGraph { title description { text(truncate: { words: 20 }) } image { url url2x thumbnails { card { url url2x } bubble { url url2x } } } }
    id slug name path
  outlineNumber
  color { __typename id slug name value }
  intro { id text }
  description { id text }
  organisations { 
    id name slug url
    type { id name slug }
    technologies { id name slug }
    countries { id slug name path }
  }
  embeds {
    citations {
      slug url name authors { id name slug } 
      authorShowsEtAl edition publication accessDate 
      startPage volume footnoteNumber year
    }
    imageAssets {
      id
      slug
      image {
        id url url2x width height 
      }
    }
  }
  
  video {
    id name url
    openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
  }
  color { id name slug value darkValue veryDarkValue }
  banner {
    id
    image {
      id
      url
      thumbnails {
        mainBanner { id url }
      }
    }
  }
  fileAssets {
    id name slug file { id extension formattedSize url typeName } __typename
    openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
  }
  citations {
    __typename _schema { label pluralLabel }
    id path slug url name path
    authors { id name slug } authorShowsEtAl edition publication date accessDate startPage volume footnoteNumber year
  }
  feedEntries {
    __typename _schema { label pluralLabel }
    id name slug url path
    types { id name slug }
    openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
  }
  posts {
    __typename _schema { label pluralLabel }
    id name slug path __typename published
    openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
    people { id name slug path }
  }
  moderators {
    __typename id name slug position
    openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
    organisation { id name slug }
  }
  } }
}
`