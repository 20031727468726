import React from 'react';
import styled, { css } from 'styled-components';
import { Citation, Classable, FileAsset, HasChildren, Page, Post, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { EntityCard, EntityFilters, EntityGrid, ExplorerEntityGridLayout, SearchBar, WaitMessage, useSearch, useTrends, useView } from '@shapeable/ui';
import { useQuery, gql } from '@apollo/client';
import { classNames, linkedMatchesFilter } from '@shapeable/utils';
import { sortBy, compact } from 'lodash';
import { Catalyst, Trend } from '@shapeable/copper-connect-types';
const cls = classNames('explorer-library-layout');

// -------- Types -------->

export type ExplorerLibraryLayoutProps = Classable & HasChildren & {
}

export const ExplorerLibraryLayoutDefaultProps: ExplorerLibraryLayoutProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`

  `,
});

const FiltersStyles = breakpoints({
  base: css`

  `,
});

const WaitMessageStyles = breakpoints({
  base: css`
  `,
});

// -------- Components -------->

const My = {
  Container: styled(ExplorerEntityGridLayout)<ContainerProps>`${ContainerStyles}`,
    Filters: styled(EntityFilters)`${FiltersStyles}`, 
    WaitMessage: styled(WaitMessage)`${WaitMessageStyles}`,
};


export const ExplorerLibraryLayout: Shapeable.FC<ExplorerLibraryLayoutProps> = (props) => {
  const { className } = props;
  const { resultsFor, filters } = useView('explorer', 'all');
  const { themes = [], posts = [], citation = [],  } = filters;

  const allTrends = useTrends();

  const { loading, data } = useQuery(
    gql`
      query { 
        allFileAssets {
          id name slug file { id extension formattedSize url typeName }
          trends { id name slug }
        }
        allCitations {
          __typename _schema { label pluralLabel }
          id path slug url name
          trends { id name slug }
          authors { id name slug } authorShowsEtAl edition publication date accessDate startPage volume footnoteNumber year
        }
        allPosts {
          id path name slug
          openGraph { id image { id url } }
          trends { id name slug }
          type { id name }
        }
        listPages(filter: { types: { in: ["impact-strategy"]} }) {
          results {
            name path slug __typename _schema { label pluralLabel }
            trends { id name slug }
            openGraph { id image { id url } }
          }
        }
        allCatalysts {
          id name path slug __typename _schema { label pluralLabel }
          openGraph { id image { thumbnails { card { id url } } } }
        }
      }
  `);

  const pages = data?.listPages?.results || [];
  const catalystsData = data?.allCatalysts || [];

  const pagesWithName = pages.map((page: Page) => {
      return {
        ...page,
        _schema: {
          label: 'Impact Strategy',
        }
      }
    })

  const filteredPosts = data?.allPosts.filter((post: Post) => (
    linkedMatchesFilter(post.trends, themes)
  ));

  const filteredCitations = data?.allCitations.filter((citation: Citation) => (
    linkedMatchesFilter(citation.trends, themes)
  ));

  const filteredPages = pagesWithName.filter((page: Page) => (
    linkedMatchesFilter(page.trends, themes)
  ));

 // temporary fix for catalysts
  const filteredCatalysts = themes.length > 0
  ? allTrends
      .filter((trend: Trend) => 
        themes.some((theme) => theme === trend.slug)
      )
      .flatMap((trend: Trend) => trend.catalysts || [])
  : catalystsData;


  const libraryItems = sortBy([
    ...resultsFor({ type: 'Post', data: filteredPosts, by: 'name' }),
    ...resultsFor({ type: 'Citation', data: filteredCitations, by: 'name' }),
    ...resultsFor({ type: 'FileAsset', data: data?.allFileAssets, by: 'name' }),
    ...(resultsFor({ type: 'Catalyst', data: filteredCatalysts, by: 'name' })), 
    ...(resultsFor({ type: 'ImpactStrategy', data: filteredPages, by: 'name' })), 
  ], 'name');

  return (
    <My.Container 
      view="explorer"
      className={cls.name(className)}
      showSearchBar
      loading={loading}
      items={libraryItems}
      filters={
        <My.Filters 
          view="explorer"
          selects={[
            {
              name: 'themes',
              fieldName: 'trends',
              items: sortBy(allTrends, 'outlinenumber'),
              pluralLabel: 'Value Chain Themes',
            },
          ]}
          typeToggles={compact([
            { name: "Post", label: "Case Studies" },
            { name: "Citation", label: "Scientific Papers" },
            !!data?.allFileAssets.length &&
            { name: "FileAsset", label: 'Files' },
            { name: 'Catalyst' },
            { name: "ImpactStrategy", label: "Impact Strategies" },
          ])} 
        />
      } 
    /> 
  )
};

ExplorerLibraryLayout.cls = cls;
ExplorerLibraryLayout.defaultProps = ExplorerLibraryLayoutDefaultProps;
