import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Page, Post, Shapeable, ContentNode as TContentNode } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, linkedMatchesFilter } from '@shapeable/utils';
import { Organisation, Person, Trend } from '@shapeable/copper-connect-types';
import { CitationsContext, ComponentPropsProvider, ContentEntityIntro, ContentNode, ContentTabs, DottedLine, EntityFilters, EntityGrid, ExplorerEntityGridLayout, ExplorerView, NextPageLink, SearchBar, useEconomies, useOrganisationTypes, useSearch, useView } from '@shapeable/ui';
import { CommodityGroupDepositList } from './commodity-group-deposit-list';
import { GptLayout } from '@shapeable/gpt';
import { TrendView } from '../../types';
import { compact, sortBy } from 'lodash';
import { useTrendViewDefinition } from '../../hooks/use-trend-view-definition';
const cls = classNames('trend-view-layout');

// -------- Types -------->

// -------- Props -------->

export type TrendViewLayoutProps = Classable & HasChildren & { 
  entity?: Trend;
};

export const TrendViewLayoutDefaultProps: Omit<TrendViewLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    margin: ${theme.UNIT(7)} 0;
  `,
});

const SectionStyles = breakpoints({
  base: css`
    margin: ${theme.UNIT(5)} 0;
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(5)};
  `,
});

const ContentTitleStyles = breakpoints({
  base: css`
    
  `,
});

const DescriptionStyles = breakpoints({
  base: css`
    
  `,
});


const TabsStyles = breakpoints({
  base: css`
    width: 100%;
    padding-top: ${theme.UNIT(1)};
    margin-top: ${theme.UNIT(2)}; 
  `,
});

const IntroStyles = breakpoints({
  base: css`
    font-size: 1.35em;
  `,
});

const NextLinkStyles = breakpoints({
  base: css`
    margin: ${theme.UNIT(5)} 0;
    
  `,
});

const EntityGridStyles = breakpoints({
  base: css`
    
  `,
});

const GptLayoutStyles = breakpoints({
  base: css`
    .shp--gpt-prompt {
      &:first-child {
        border-top: none;
      }
    };
  `,
});

const DepositsStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
  `,
});

const SearchBarStyles = breakpoints({
  base: css`
    
  `,
});

const FiltersStyles = breakpoints({
  base: css`
    
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Section: styled.section`${SectionStyles}`,
      ContentTitle: styled.h2`${ContentTitleStyles}`,
      Description: styled(ContentNode)`${DescriptionStyles}`,
      Intro: styled(ContentEntityIntro)`${IntroStyles}`,
      Filters: styled(EntityFilters)`${FiltersStyles}`, 

      Deposits: styled(CommodityGroupDepositList)`${DepositsStyles}`,

      SearchBar: styled(SearchBar)`${SearchBarStyles}`,

      StakeholderGrid: styled(EntityGrid)`${EntityGridStyles}`,

      LibraryGrid: styled(EntityGrid)`${EntityGridStyles}`,

      EntityGrid: styled(EntityGrid)`${EntityGridStyles}`,
      Tabs: styled(ContentTabs)`${TabsStyles}`,

      NextLink: styled(NextPageLink)`${NextLinkStyles}`,

      GptLayout: styled(GptLayout)`${GptLayoutStyles}`,
};

export const TrendViewLayout: Shapeable.FC<TrendViewLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const { description, posts = [], pages = [], organisations = [], people = [], citations } = entity;

  // const libraryItems = compact([...posts, ...feedEntries, ...transformedPages, ...citations ]);

  const { hasDeposits, defaultView, copper, filteredLibraryItems, stakeholders } = useTrendViewDefinition(entity);

  const { searchTerm, setSearchTerm, filteredItems } = useSearch(filteredLibraryItems);

  const onChange = (name: string) => { 
    setSearchTerm(name);
   };

  const view = `trend-${entity.path}`;

  const { showView, filters, resultsFor } = useView<TrendView>(view, 'overview');
  const { type = [], economies = [] } = filters;

  const allOrganisationTypes = useOrganisationTypes();
  const allEconomies = useEconomies();

  const stakeholderResults = [...organisations, ...people];

  const filteredOrganisations = organisations.filter(organisation => (
    linkedMatchesFilter(compact([organisation?.type]), type) &&
    linkedMatchesFilter([organisation?.country], economies)
  ));

  const filteredPeople = people.filter(person => (
    linkedMatchesFilter([person?.economy], economies)
  ));

  const filteredStakeholders = [...filteredOrganisations, ...filteredPeople];
  const sortedLibraryItems = sortBy(filteredItems, 'name');
  

  return (
    <ComponentPropsProvider value={{ 
      EntityCard: {
        showDescription: false,
      }
     }}>
    <My.Container className={cls.name(className)}>
      {
        showView('copper-ai') && 
        <My.Section>
          <My.GptLayout entity={entity} />
        </My.Section>
      }
      {
        showView('overview') && 
        <My.Section>
          <My.Intro entity={entity} />
          <My.Description entity={description as TContentNode} />
        </My.Section>
      }
      {
        showView('deposits') &&
        <My.Section>
          {
            hasDeposits && 
            <CommodityGroupDepositList entity={copper} />
          }
        </My.Section>
      }
      {
        showView('stakeholders') &&
        <>
          <My.Filters 
            results={stakeholderResults}
            view={view}
            selects={compact([
              {
                name: 'type',
                items: sortBy(allOrganisationTypes, 'name'), 
                pluralLabel: 'Stakeholder Types',
                getResultFieldSlugs: (result: Organisation) => [result?.type?.slug],
              },
              {
                name: 'economies',
                items: sortBy(allEconomies, 'name'),
                pluralLabel: 'Countries',
                getResultFieldSlugs: (result: Organisation | Person) => [result.country?.slug],
              }
            ])}
          />
          <My.Section>
            {
              <My.StakeholderGrid spacing={2} items={filteredStakeholders} />
            }
          </My.Section>
        </>
      }
      {
        showView('library') && 
        <>
          <My.Filters 
              results={stakeholderResults}
              view={view}
              typeToggles={compact([
                !!posts.length &&
                { name: "Post", label: "Case Studies" },
                !!citations.length &&
                { name: "Citation", label: "Scientific Papers" },
                !!pages.length &&
                { name: "ImpactStrategy", label: "Impact Strategies" },
              ])}
            />
          <My.Section>
            <My.SearchBar variant='allBorders' searchTerm={searchTerm} onChange={onChange} placeholder={`Search Library`}/>
            <My.LibraryGrid 
              spacing={4} 
              minColumns={1}
              tabletAutoColumns={2}
              tabletMinColumns={2}
              desktopAutoColumns={3}
              desktopFullHdAutoColumns={4}
              desktopFullHdMinColumns={4}
              desktopLargeAutoColumns={4}
              desktopLargeMinColumns={4}
              items={sortedLibraryItems}/>
          </My.Section>
        </>
      }
      <DottedLine height={2} />
      <My.NextLink />
    </My.Container>
    </ComponentPropsProvider>
  )
};

TrendViewLayout.defaultProps = TrendViewLayoutDefaultProps;
TrendViewLayout.cls = cls;