import { graphql } from 'gatsby';
import { createGatsbyPageComponent } from '@shapeable/ui';
import { InnovationExplorerLayout } from '../../components/entities/innovation-explorer-layout';

/* @ts-ignore */
export default createGatsbyPageComponent('Innovation', { layout: InnovationExplorerLayout });

export const query = graphql`
query InnovationQuery($id: ID!) {
  platform { innovation(id: $id) {
    __typename _schema { label pluralLabel }
    openGraph { title description { text(truncate: { words: 20 }) } image { url url2x thumbnails { card { url url2x } bubble { url url2x } } } }
    id slug name
    topic { 
      id slug name path
      trend {
        id slug name path
      } 
    }
    description { id text }
    example { id text }
    technologies {
      id name slug __typename _schema { label pluralLabel }
      openGraph { image { id url url2x } }
      organisations { 
        id name slug url
        type { id name slug }
        technologies { id name slug }
        logo { url url2x }
        openGraph { id image { id url url2x thumbnails { bubble { url url2x } } } }
      }
    }
    catalysts {
      id name path slug __typename _schema { label pluralLabel }
      openGraph { image { url url2x } }
      description { text }
    }
    impactResultTypes {
      id name slug __typename _schema { label pluralLabel }
      openGraph { image { url url2x } }
    }
    commodityGroups {
      id name slug __typename _schema { label pluralLabel }
      openGraph { image { url url2x } }
    }
    barriers {
      id name slug __typename _schema { label pluralLabel }
      openGraph { image { url url2x } }
    }
    horizons {
      name path __typename slug _schema { label pluralLabel }
      type { id name value }
      description { text }
    }
    sdgs {
      id name __typename slug _schema { label pluralLabel }
      openGraph { image { url url2x } }
    }
  } }
}
`;