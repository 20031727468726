import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue, TrendsProvider as Provider } from '@shapeable/ui';
import { sortBy } from 'lodash';

const TRENDS_QUERY = graphql`
query PlatformAllTrendsQuery {
  
    platform { allTrends {
      
        
      
          
      
        
      
            id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
      
        
      
          
      
          
      
                openGraph { 
      
                  id title 
      
                  
      
                  image { 
      
                    id url url2x 
      
                    thumbnails { 
      
                      id bubble { id url url2x }
      
          card { id url url2x }
      
                    }
      
                    
      
                  } 
      
                }
      
              
      
        
        dataSetId
        outlineNumber
        color {    id value veryLightValue lightValue darkValue veryDarkValue  }
        topics { 
           
            id name slug typeLabel badge path updated __typename _schema { label pluralLabel } 
          
          outlineNumber
          color {    id value veryLightValue lightValue darkValue veryDarkValue  }
          citations { id name slug path }
        }
      
    }
  
  allTrends {
    organisations {
      type { id slug}
      
        
      
          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
      
        
        
              openGraph { 
                id title 
                
                image { 
                  id url url2x 
                  thumbnails { 
                    id bubbleMedium { id url url2x }
                  }
                  
                } 
              }
            
      
    }
    topics { 
      innovations { 
         
           
         
            id name slug typeLabel badge path updated __typename _schema { label pluralLabel } 
         
           
           
                openGraph {  
                  id title  
                   
                  image {  
                    id url url2x  
                    thumbnails {  
                      id bubbleMedium { id url url2x } 
                    } 
                     
                  }  
                } 
               
        
      }
      subTopics {
        
          
        
            id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
        
          
          
                openGraph { 
                  id title 
                  
                  image { 
                    id url url2x 
                    thumbnails { 
                      id bubbleMedium { id url url2x }
                    }
                    
                  } 
                }
              
        
        outlineNumber
      }
    }
    catalysts {
      
        
      
          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
      
        
        
              openGraph { 
                id title 
                
                image { 
                  id url url2x 
                  thumbnails { 
                    id bubbleMedium { id url url2x }
                  }
                  
                } 
              }
            
      
    }
  } }

}

`;

export const TrendsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ sortBy(platformProviderValue( useStaticQuery(TRENDS_QUERY), 'allTrends' ), 'outlineNumber') }>{children}</Provider>;
};