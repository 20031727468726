import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, EntityTypeName, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { CellLabel, SidebarButton, useAuth, useContributeForm, useEntity, useLang, useView } from '@shapeable/ui';
import { includes, without } from 'lodash';
import { useValueChainMap } from '../../hooks/use-value-chain-map';
import { classNames } from '@shapeable/utils';
import { EntityMapToggles, useMapUtils } from '@shapeable/maps';
import { ConnectionsNavigatorIconGlyph, FeedbackIconGlyph, WorldMapIcon } from '@shapeable/icons';
import { isExplorerEntity } from '../../utils/is-explorer-entity';
import { WorldMapIconGlyph } from '@shapeable/icons';
import { ExplorerView } from '../../types';
const cls = classNames('sidebar-buttons');

// -------- Types -------->

export type SidebarButtonsProps = Classable & HasChildren & {
}

export const SidebarButtonsDefaultProps: SidebarButtonsProps = {
};

// -------- Styles -------->

const IconStyles = breakpoints({
  base: css`
    width: 32px;
    height: 32px;
    margin-bottom: ${theme.UNIT(2)};
  `,

});

const TogglesStyles = breakpoints({
  base: css`
    
  `,
});

const ExplorerMapStyles = breakpoints({
  base: css`
    display: flex;
  `,
  desktop: css`
    display: none;
  
  `,
});

const WorldMapStyles = breakpoints({
  base: css`
    
  `,
});


const WorldMapIconStyles = breakpoints({
  base: css`
    width: 36px;
    height: 22px;
    display: none;
    margin-bottom: ${theme.UNIT(3)};
  `,
  tablet: css`
    display: flex;
  `
});

const ContactButtonStyles = breakpoints({
  base: css`

  `,

});

const ContactStyles = breakpoints({
  base: css`
    width: 24px;
    height: 24px;
    margin-bottom: 12px;
  `,
  
});


// -------- Components -------->

const My = {
  Container: React.Fragment,
    Toggles: styled(EntityMapToggles)`${TogglesStyles}`,
    ExplorerMap: styled(SidebarButton)`${ExplorerMapStyles}`,
    Icon: styled(ConnectionsNavigatorIconGlyph)`${IconStyles}`,
    WorldMap: styled(SidebarButton)`${WorldMapStyles}`,
    WorldMapIcon: styled(WorldMapIconGlyph)`${WorldMapIconStyles}`,

    ContactButton: styled(SidebarButton)`${ContactButtonStyles}`,
      ContactIcon: styled(FeedbackIconGlyph)`${ContactStyles}`,
};

export const SidebarButtons: Shapeable.FC<SidebarButtonsProps> = (props) => {
  const { className } = props;
  const t = useLang();
  const valueChainMap = useValueChainMap();
  const map = useMapUtils();
  const auth = useAuth();
  const entity = useEntity();
  const contributeForm = useContributeForm();

  const { activeView, setActiveView } = useView<ExplorerView>('explorer');

  return (
    <My.Container>
      {
        !map.isVisible && 
        <My.WorldMap onClick={map.showOnClick} icon={<My.WorldMapIcon/>}>{t('World Map')}</My.WorldMap>
      }
      {
        //Removing ContactButton from mobile temporarily. Takes up to much space with 4 sidebar buttons
        <My.ContactButton onClick={contributeForm.showOnClick} icon={<My.ContactIcon />}>{t('Contact Us')}</My.ContactButton>
      }
    </My.Container>
  )
};

SidebarButtons.cls = cls;
SidebarButtons.defaultProps = SidebarButtonsDefaultProps;