import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue } from '@shapeable/ui';
import { InnovationsProvider as Provider } from '../../components/context/innovations-context';

const QUERY = graphql`
query PlatformAllInnovationsQuery {
  platform { allInnovations {
    
      
    
        id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
    
      
      
            openGraph { 
              id title 
              
              image { 
                id url url2x 
                thumbnails { 
                  id bubble { id url url2x }
      card { id url url2x }
                }
                
              } 
            }
          
    
    horizons { id __typename type { id slug value } }
    trends { id slug __typename }
    technologies { id name slug __typename }
    catalysts { id name slug __typename }
    innovationPriority { id slug name __typename }
  } }
}

`

export const InnovationsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allInnovations' ) }>{children}</Provider>;
};