import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, ContentNode, Entity, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { useOrganisations, usePeople, useOrganisationTypes, useEconomies, useView, ExplorerEntityGridLayout, EntityFilters } from '@shapeable/ui';
import { compact, sortBy } from 'lodash';
import { linkedMatchesFilter } from '../../utils/linked-matches-filter';
import { Organisation, Person } from '@shapeable/copper-connect-types';
import { useCommunityFilters } from '../../hooks/use-community-filters';
import { classNames } from '@shapeable/utils';
import innovation from 'gatsby/entities/innovation';
const cls = classNames('explorer-community-layout');

// -------- Types -------->

export type ExplorerCommunityLayoutProps = Classable & HasChildren & {
} 

export const ExplorerCommunityLayoutDefaultProps: ExplorerCommunityLayoutProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const FiltersStyles = breakpoints({
  base: css`

  `,
});


// -------- Components -------->

const My = {
  Container: styled(ExplorerEntityGridLayout)<ContainerProps>`${ContainerStyles}`,
    Filters: styled(EntityFilters)`${FiltersStyles}`,
};


export const ExplorerCommunityLayout: Shapeable.FC<ExplorerCommunityLayoutProps> = (props) => {
  const { className } = props;
  const organisationTypes = useOrganisationTypes();
  const economies = useEconomies();
  const view = 'explorer';
  const { filters } = useView(view);
  const { countries = [], technologies = [], themes = [] } = filters;

  const allPeople = usePeople();
  const community = useCommunityFilters('explorer-community');
  const { selects } = community;

  const people = sortBy(allPeople.filter((person: Person) => (
    linkedMatchesFilter([person.economy], countries) &&
    linkedMatchesFilter(person.technologies, technologies) &&
    linkedMatchesFilter(person.trends, themes)
  )), 'name');

  const description: ContentNode = {
    text: "The members of the CopperConnect network, ranging across Executives, Consultants, Researchers, Government and METS"
  }
  
  return (
    <My.Container 
      className={cls.name(className)} 
      view={view}
      items={people}
      showSearchBar
      description={description}
      filters={
        <My.Filters
        view={view}
        results={allPeople}
        selects={selects}
        />
       }
    />
  );
};

ExplorerCommunityLayout.cls = cls;
ExplorerCommunityLayout.defaultProps = ExplorerCommunityLayoutDefaultProps;