import React, { memo, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Topic } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, entityOutlineNumberRank } from '@shapeable/utils';
import { SvgEntityNavigator, useTrends, useEntityNavigatorNodes } from '@shapeable/ui';
import { SubTopic, Trend } from '@shapeable/copper-connect-types';
const cls = classNames('value-chain-navigator');

// -------- Types -------->

// -------- Props -------->

export type ValueChainNavigatorProps = Classable & HasChildren & {
}

export const ValueChainNavigatorDefaultProps: ValueChainNavigatorProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

// -------- Components -------->

const My = {
  Container: styled(SvgEntityNavigator)<ContainerProps>`${ContainerStyles}`,
};

export const ValueChainNavigator: React.FC<ValueChainNavigatorProps> = memo((props) => {
  const { className } = props;

  const trends = useTrends();

  const nodes = useEntityNavigatorNodes<Trend, Topic>({
    items: trends,
    fieldNameChildren: 'topics',
    sort: entityOutlineNumberRank,
    sortChildren: entityOutlineNumberRank,
  });
  
  return (
    <My.Container nodes={nodes} className={cls.name(className)} />
  )
});

// @ts-ignore
ValueChainNavigator.cls = cls;
ValueChainNavigator.defaultProps = ValueChainNavigatorDefaultProps;