import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, OutlineEntity, Shapeable } from '@shapeable/types';
import { breakpoints } from '@shapeable/theme';
import { useView, EntityFilters, ExplorerEntityGridLayout, useTrends, useTopics } from '@shapeable/ui';
import { useTrendsExplorer } from '../../hooks/use-trends-explorer';
import { useMemo } from 'react';
import { sortBy, each, includes } from 'lodash';
import { classNames, linkedMatchesFilter } from '@shapeable/utils';
import { ExplorerView } from '../../types';
import { Topic } from '@shapeable/copper-connect-types';

const cls = classNames('explorer-theme-layout');

// -------- Types -------->

export type ExplorerThemesLayoutProps = Classable & HasChildren & {
}

export const ExplorerThemesLayoutDefaultProps:  ExplorerThemesLayoutProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const FiltersStyles = breakpoints({
  base: css`

  `,
});

// -------- Components -------->

const My = {
  Container: styled(ExplorerEntityGridLayout)<ContainerProps>`${ContainerStyles}`,
    Filters: styled(EntityFilters)`${FiltersStyles}`,
};

export const ExplorerThemesLayout: Shapeable.FC< ExplorerThemesLayoutProps> = (props) => {
  const { className } = props;

  const allTrends = useTrends();
  const allTopics = useTopics();

  const view = 'explorer';
  const { types, filters, resultsFor } = useView<ExplorerView>(view);
  const { themes = [] } = filters;

  const filteredTrends = allTrends.filter(trend => (
    linkedMatchesFilter([trend], themes) 
  ));

  const filteredTopics = allTopics.filter(topic => (
    linkedMatchesFilter([topic?.trend], themes) 
  ));

  const toggledItems = [
    ...(resultsFor({ type: 'Trend', data: filteredTrends, by: 'name' })), 
    ...(resultsFor({ type: 'Topic', data: filteredTopics, by: 'name' })),
  ]

  const gridItems = sortBy(toggledItems, 'outlineNumber');

  return (
    <My.Container 
      view={view}
      className={cls.name(className)} 
      items={gridItems}
      showSearchBar
      filters={
        <My.Filters 
          view={view}
          selects={[
            {
              name: 'themes',
              fieldName: 'trends',
              items: allTrends,
              pluralLabel: 'Value Chain Themes',
            },
            ]}
          typeToggles={[
            { name: 'Trend', label: 'Value Chain Themes' },
            { name: 'Topic', label: 'Topics' },
          ]}
         />
       }
    />
  )
};

 ExplorerThemesLayout.cls = cls;
 ExplorerThemesLayout.defaultProps =  ExplorerThemesLayoutDefaultProps;