import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue } from '@shapeable/ui';
import { CommodityGroupsProvider as Provider } from '../../components/context/commodity-groups-context';

const QUERY = graphql`
query PlatformAllCommodityGroupsQuery {
  platform { allCommodityGroups {
    
      
    
        id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
    
      
      
            openGraph { 
              id title 
              
              image { 
                id url url2x 
                thumbnails { 
                  id bubble { id url url2x }
      card { id url url2x }
                }
                
              } 
            }
          
    
  } }
}

`;

export const CommodityGroupsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allCommodityGroups' ) }>{children}</Provider>;
}