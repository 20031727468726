import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme, ColorTokens } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { SubTopic } from '@shapeable/copper-connect-types';
import { ContentTabs, ContentTabSpec, useView } from '@shapeable/ui';
import { AiUpperCaseIconGlyph } from '@shapeable/icons';
import { compact } from 'lodash';
import { SubTopicView } from 'types';

const cls = classNames('sub-topic-tabs-layout');

// -------- Types -------->

// -------- Props -------->

export type SubTopicTabsLayoutProps = Classable & HasChildren & { 
  entity?: SubTopic;
};

export const SubTopicTabsLayoutDefaultProps: Omit<SubTopicTabsLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const TabsStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Tabs: styled(ContentTabs)<ContainerProps>`${TabsStyles}`,
};

export const SubTopicTabsLayout: Shapeable.FC<SubTopicTabsLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const { feedEntries = [], pages = [], posts = [], citations = [], 
    color } = entity;

  const libraryItems = compact([...posts, ...feedEntries, ...pages, ...citations ]);

  const contentTabColors: ColorTokens.Any = {
      hover: {
        text: color?.value,
        fill: color?.value,
      },
      active: {
        text: color?.value,
        fill: color?.value,
        border: color?.value,
      }
    };

  const tabs: ContentTabSpec<SubTopicView>[] = compact([
      {
        id: 'copper-ai',
        icon: <AiUpperCaseIconGlyph size={30} />
      },
      {
        id: 'deep-dive',
        label: 'Deep Dive',
      },
      !!libraryItems.length &&
      {
        id: 'library',
        label: 'References',
      },
    ]);
  
      const view = entity.path;
    const { activeView, setActiveView } = useView<SubTopicView>(view, 'deep-dive');
    
    return (
      <My.Tabs onTabClick={setActiveView} activeTab={activeView} items={tabs} colors={contentTabColors} />
    )
};

SubTopicTabsLayout.cls = cls;