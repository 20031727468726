import { useEconomies, usePeople, useTrends } from '@shapeable/ui';
import { useTechnologies } from './use-technologies';
import { compact, sortBy } from 'lodash';
import { Economy, Person, Technology, Trend } from '@shapeable/copper-connect-types';

export type CommunityFiltersVariant = 'explorer-community' | 'innovation-community';
export const useCommunityFilters = (page?: CommunityFiltersVariant ) => {
  const allTechnologies = useTechnologies();
  const allEconomies = useEconomies();
  const allTrends = useTrends();
  const allPeople = usePeople();

  const filterCountries = ( entities: Economy[], person: (Person)[] ) => {
    return (entities || []).filter((entity) =>
      (person || []).some((person) => person.economy?.slug === entity.slug)
    );
  };

  const filterTrends = (entities: Trend[], person: Person[]) => {
    return (entities || []).filter((trend) =>
      (person || []).some((person) => person.trends?.some((orgTrend) => orgTrend.slug === trend.slug))
    );
  };

  const filterTechnologies = (entities: Technology[], person: Person[]) => {
    return (entities || []).filter((tech) =>
      (person || []).some((person) => person.technologies?.some((orgTech) => orgTech.slug === tech.slug))
    );
  }

  const finalCountries = filterCountries(allEconomies, allPeople);
  const finalTechnologies = filterTechnologies(allTechnologies, allPeople);
  const finalTrends = filterTrends(allTrends, allPeople);

  return {
    selects: compact([
      // !!finalTrends.length &&
      // {
      //   name: 'themes',
      //   fieldName: 'trends',
      //   items: sortBy(finalTrends, 'outlineNumber'),
      //   pluralLabel: 'Value Chain Themes',
      //   getResultFieldSlugs: (result: Person) => result.trends?.map((trend) => trend.slug),
      // },
      !!finalCountries.length && 
      {
        name: 'countries',
        items: sortBy(finalCountries, 'name'),
        pluralLabel: 'Countries',
        getResultFieldSlugs: (result: Person) => [result.economy?.slug],
      },
      // !!finalTechnologies.length &&
      // {
      //   name: 'technologies',
      //   items: sortBy(finalTechnologies, 'name'),
      //   pluralLabel: 'Technologies',
      //   getResultFieldSlugs: (result: Person) => result.technologies?.map((tech) => tech.slug),
      // },
    ]),
    typeToggles: [
      { name: 'Organisation' },
      { name: 'Person' },
    ]
  };
};