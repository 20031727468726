import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue } from '@shapeable/ui';
import { CatalystsProvider as Provider } from '../../components/context/catalysts-context';

const QUERY = graphql`
query PlatformAllCatalystsQuery {
  platform { allCatalysts {
    
      
    
        id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
    
      
      
            openGraph { 
              id title 
              
              image { 
                id url url2x 
                thumbnails { 
                  id bubble { id url url2x }
      card { id url url2x }
                }
                
              } 
            }
          
    
    innovations {          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }                openGraph {            id title                       image {              id url url2x              thumbnails {                id bubbleMedium { id url url2x }             }                        }          }         }
  } }
}

`;

export const CatalystsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allCatalysts' ) }>{children}</Provider>;
}