
import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, ContentEntity, EntityTypeName, HasChildren, Page, Shapeable, ContentNode as TContentNode } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { applyOpenGraphThumbnailSize, classNames, entityTypeNameFor } from '@shapeable/utils';
import { CellLabel, ComponentPropsProvider, ContentEntityIntro, ContentEntityRichTitle, EntityCard, EntityGrid, FeedEntryCard, GptButton, LinkButton, useEntityTypeCards, useFeedEntries, useLang, usePosts } from '@shapeable/ui';
import { sortBy } from 'lodash';
import { SendIcon, SendIconGlyph } from '@shapeable/icons';
const cls = classNames('explorer-home-view-layout');

// -------- Types -------->

// -------- Props -------->

export type ExplorerHomeViewLayoutProps = Classable & HasChildren & { 
  entity?: Page;
};

export const ExplorerHomeViewLayoutDefaultProps: Omit<ExplorerHomeViewLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(5)};
  `,
});

const ContentStyles = breakpoints({
  base: css`
    padding-bottom: ${theme.UNIT(5)};
  `,
});

const SectionStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(7)};
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(3)};
  `,
});

const PageCardsStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(3)};
    margin-bottom: ${theme.UNIT(3)};
  `,
});

const TitleAndIntroStyles = breakpoints({
  base: css`
    
  `,
});

const IntroStyles = breakpoints({
  base: css`
    font-size: 1.10em;
    line-height: 1;
  `,
});

const TitleStyles = breakpoints({
  base: css`
    font-weight: 300;
    margin-bottom: ${theme.UNIT(4)};
    line-height: 1.10;
    font-size: 1.8em;
    color: ${theme.COLOR('strong')};
  `,
});

const ContentLabelStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('text')};
    font-weight: 300;
  `,
});

const CopperAiTextValueStyles = breakpoints({
  base: css`
    
  `,
});

const FeaturedNewsStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(4)};
  `,
});

const FeaturedNewsCardStyles = breakpoints({
  base: css`
    
  `,
});

const DiscoverButtonStyles = breakpoints({
  base: css`
    max-width: 300px;
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,

  PageCards: styled(EntityGrid)`${PageCardsStyles}`,
    TitleAndIntro: styled.div`${TitleAndIntroStyles}`,
    Title: styled(ContentEntityRichTitle)`${TitleStyles}`,
    Intro: styled(ContentEntityIntro)`${IntroStyles}`,

  Content: styled.div`${ContentStyles}`,
    Section: styled.section`${SectionStyles}`,
      ContentLabel: styled(CellLabel)`${ContentLabelStyles}`,
      GptButton: styled(GptButton)`${CopperAiTextValueStyles}`,
      FeaturedNews: styled.div`${FeaturedNewsStyles}`,
      FeaturedNewsCard: styled(FeedEntryCard)`${FeaturedNewsCardStyles}`,

    DiscoverButton: styled(LinkButton)`${DiscoverButtonStyles}`,
};

export const ExplorerHomeViewLayout: Shapeable.FC<ExplorerHomeViewLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const { cardLinks = [], bannerLinks = [] } = entity;

  const ENTITY_CARD_COMPONENTS = useEntityTypeCards();

  const feedEntries = useFeedEntries();
  const posts = usePosts();
  const allItems = sortBy([...feedEntries, ...posts], 'published') as ContentEntity[];
  
  const applyCardThumbnails = applyOpenGraphThumbnailSize('card');
  const featurePagesTrimmed = cardLinks.slice(0, 4).map(applyCardThumbnails);

  const hasLinkCards = !!featurePagesTrimmed.length;
 
  const t = useLang();
  
  return (
    <ComponentPropsProvider value={{
      FeedEntryCard: {
        direction: 'row',
        descriptionMaxLines: 2,
      },
      PostCard: {
        direction: 'row',
        descriptionMaxLines: 2,
      }
    }}>
    <My.Container className={cls.name(className)}>
      {
        hasLinkCards && 
        <My.PageCards 
          items={featurePagesTrimmed} 
          spacing={3} 
          minColumns={2}
          tabletAutoColumns={4}
          desktopAutoColumns={4}
        />
      }
      <My.Content>
        <My.Section>
          <My.Title entity={entity} />
          <My.Intro entity={entity} />
        </My.Section>

        <My.Section>
          <My.ContentLabel>{t('Generate personalised results with:')}</My.ContentLabel>
          <My.GptButton href='#view=ai' entity={entity} label='Use CopperAI' />
        </My.Section>

        <My.Section>
            <My.ContentLabel>{t('Latest News')}</My.ContentLabel>
            <My.FeaturedNews>
            {
              allItems.slice(3, 7).map((item, index) => {
                const Card = ENTITY_CARD_COMPONENTS[entityTypeNameFor(item) as EntityTypeName] || EntityCard;
                return (
                  <Card entity={item} key={index} />
                )
              })
            }
            </My.FeaturedNews>
        </My.Section>
        <My.DiscoverButton href='/news' shape='rounded' >{t('Discover All News')}</My.DiscoverButton>

      </My.Content>

    {children}
    </My.Container>
    </ComponentPropsProvider>
  )
};

ExplorerHomeViewLayout.defaultProps = ExplorerHomeViewLayoutDefaultProps;
ExplorerHomeViewLayout.cls = cls;