import { graphql, useStaticQuery } from "gatsby";
import * as React from "react"; // tslint:disable-line
import { platformProviderValue } from "@shapeable/ui";
import { TechnologiesProvider as Provider } from "../../components/context/technologies-context";

const QUERY = graphql`
query PlatformAllTechnologiesQuery {
  platform { allTechnologies {
    
      
    
        id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
    
      
      
            openGraph { 
              id title 
              
              image { 
                id url url2x 
                thumbnails { 
                  id bubble { id url url2x }
      card { id url url2x }
                }
                
              } 
            }
          
    
  } }
}

`

export const TechnologiesProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allTechnologies' ) }>{children}</Provider>;
}