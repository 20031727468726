import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue } from '@shapeable/ui';
import { OrganisationTypesProvider as Provider } from '@shapeable/ui';

const QUERY = graphql`
query PlatformAllOrganisationTypesQuery {
  platform { allOrganisationTypes {
    id name slug __typename _schema { label pluralLabel }
    openGraph {
      id
      description {
        text(truncate: {ellipsis: "", words: 0})
      }
    }
  } }
}

`

export const OrganisationTypesProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allOrganisationTypes' ) }>{children}</Provider>;
};