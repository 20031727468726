import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue } from '@shapeable/ui';
import { InnovationPrioritiesProvider as Provider } from '../../components/context/innovation-priorities-context';

const QUERY = graphql`
query PlatformAllInnovationPrioritiesQuery {
  platform { allInnovationPriorities {
    id name slug __typename _schema { label pluralLabel }
    
  } }
}
`

export const InnovationPrioritiesProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allInnovationPriorities' ) }>{children}</Provider>;
};